<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  data: function () {
    return {
    };
  },
  mounted(){
    const token = localStorage.getItem('token')
    if(!token){
      this.$router.push({ path: `/` }) // -> /user/123
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
