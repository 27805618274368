import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";

const app = createApp(App);

// app.config.globalProperties.BaseURL = "http://localhost:3000";
app.config.globalProperties.BaseURL = "https://danalite-back.accesa.me";
app.config.globalProperties.Cloudinary = "https://api.cloudinary.com/v1_1/accesa/image/upload";
app.config.globalProperties.UploadPreset = "ryblefrx";

app.use(store).use(router).mount("#app");

let token = JSON.parse(localStorage.getItem("token"));
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
