<template>

<p v-show="errorMsg != ''" class="is-danger notification subitle">{{ errorMsg }}</p>

  <div class="login">     
    <div class="bgm bg-mobile is-hidden-desktop">
      <figure>
        <img src="/img/Casa.png" alt="" />
      </figure>
    </div>
    <div class="input-login">
      <div class="column box has-background-light registro">
        <!-- <p id="title" class="title is-5 has-text-link-dark">Iniciar sesion</p> -->
        <figure class="image">
          <img src="/img/dana.svg" />
        </figure>
        <div class="field mx-6">
          <label class="title is-5 has-text-link-dark is-pulled-left"
            >Iniciar sesion</label>
          <input
            type="text"
            class="input is-info is-focused"
            name="username"
            placeholder="Correo Electronico"
            required
            v-model="form.usr"
          />
        </div>

        <div class="field mx-6">
          <!-- <label class="">Contraseña:</label> -->
          <input
            type="password"
            class="input is-info is-focused"
            name="password"
            placeholder="Contrasena"
            required
            v-model="form.pass"
            @keyup.enter="log()"
          />
        </div>
        <input type="hidden" name="form_submitted" value="1" />

        <div class="has-text-centered">
          <input
            type="submit"
            class="button is-info is-centered"
            value="Ingresar"
            @click="log()"
          />

          <p class="has-text-grey">
            <br />
            <a class="has-text-grey">¿Olvidaste tu contraseña?</a>
          </p>
        </div>
      </div>
    </div>
    <div class="bg is-hidden-mobile id-hidden-tablet-only">
      <figure>
        <img src="/img/casa.png" alt="" />
      </figure>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "login",

  props: {
    msg: String,
  },
  data: function () {
    return {
      form: { usr: "", pass: "" },
      errorMsg: "",
      url: this.BaseURL,
    };
  },
  methods: {
    log: function () {
      let user = this.form.usr;
      let pass = this.form.pass;

      if (user != "" && pass !== "") {
        axios
          .post(this.url+"/auth/login", {
            mail: user,
            password: pass,
          })
          .then((resp) => {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + resp.data.acces_token;
            localStorage.setItem(
              "token",
              JSON.stringify(resp.data.acces_token)
            );
            this.$router.push({ path: "/projects" });
          })
          .catch((err) => {
            console.log("ERROR", err);
            this.errorMsg = "Vuelva a intentarlo";
          });

   
      }
    },
  },
  mounted(){
    const token = localStorage.getItem('token')
   
    if(token){
      this.$router.push({ path: `/projects` }) // -> /user/123
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  height: 100vh;
  display: flex;
}

.input-login,
.bg {
  width: 50%;
}

.bg {
  background: url("/img/fondoOnda.png");
  background-size: cover;
  animation: slidein 2s;
}

.bg figure {
  width: 70%;
  margin-top: 15vh;
}
.registro {
  margin: 0 auto;
  margin-top: 15vh;
  width: 60%;
}

#bg-img {
  position: absolute;
  max-height: 100vh;
  height: 100vh;
  animation: slidein 2s;
  left: 700px;
}

@keyframes slidein {
  from {
    width: 100vw;
  }

  to {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .bg {
    display: none;
  }
  .input-login {
    width: 100%;
  }

  .registro {
    width: 80%;
  }
  .login {
    display: inline;
  }

  .bg-mobile {
    width: 60%;
    margin: 0 auto;
    margin-top: 8vh;
  }
  .registro {
    margin: 0 auto;
  }

  img {
    width: 100%;
  }

  .bgm {
    background: url("/img/fondor.png");
    background-size: cover;
    width: 100%;
    margin: 0 auto;

    height: 450px;
  }

  .bgm figure {
    width: 60%;
    margin: 0 auto;
    padding: 50px 0;
  }
}
</style>
