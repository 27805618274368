import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/projects",
    name: "Projects",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Projects.vue"),
  },
  {
    path: "/newProject",
    name: "NewProject",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewProject.vue"),
  },
  {
    path: "/newUser",
    name: "NewUser",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewUser.vue"),
  },
  {
    path: "/newZone",
    name: "NewZone",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewZone.vue"),
  },
  {
    path: "/editProject/:id",
    name: "EditProject",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EditProject.vue"),
  },
  {
    path: "/editZone/:id",
    name: "EditZone",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EditZone.vue"),
  },
  {
    path: "/newUserTemp",
    name: "EditUserTemp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewUserTemp.vue"),
  },
   {
    path: "/newDistributor",
    name: "NewDistributor",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewDistributor.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
